import React from 'react'

import Layout from '../components/layout'
import { Link } from 'gatsby'
import Seo from "../components/seo"
import image_perso from '../images/PM2.jpg'
import { Helmet } from "react-helmet"
import { JsonLd } from '../components/JsonLd'

class PortfolioPage extends React.Component {
  render() {
    return (
      <Layout>
      <Seo title="Qui suis-je ?" />
      <Helmet>
        <JsonLd>
        {{
            "@context": "http://schema.org",
            "@type": "Person",
            "name": "Matthieu Genicot",
            "url": "https://www.pointdevuedata.be",
            "jobTitle": "Data Scientist",
            "alumniOf": "Université Catholique de Louvain",
            "sameAs": [
                "https://www.linkedin.com/in/matthieu-genicot-ab127183/",
                "https://twitter.com/GenicotMatthieu",
            ]
        }}
        </JsonLd>
      </Helmet>
      <div id="main">
          <article className="post featured">
          <section className="bigger_margin">
          <div className="image right">
            <br/>
            <img src={image_perso} alt="Matthieu Genicot"/>
          </div>
          <p>
            Hello&nbsp;!
            <br/>
            Moi c'est Matthieu, en vrac&nbsp;: ingénieur civil de formation, data scientist depuis plusieurs années, 
            idéaliste amateur de débats divers et variés, incurable optimiste et écologiste engagé.
            <br/>
            Mais surtout, je suis quelqu'un qui a besoin de donner du sens à ses compétences.
            Mes trucs à moi, ce sont les projets qui matchent avec mes valeurs et puis les data, dans cet ordre-là. 
            <br/>
            J'ai envie, dans cette page, de raconter en quelques lignes comment et pourquoi <em>Point de Vue Data</em> est là.
            </p>
            <h4 style={{textAlign: "left"}}>Une élection lointaine, et un gros coup de cafard&hellip;</h4>
            <p>Le jour où Donald Trump a été élu président des Etats-Unis, j'ai ressenti
              un lourd sentiment d'incompréhension. 
              Grand consommateur de médias, je faisais suffisamment 
              confiance aux sondages pour ne pas réellement envisager sa victoire, et le résultat a été une belle claque.
              <br/> Ce jour-là, j'ai commencé à prendre conscience de l'ampleur de la fracture entre les
              politiques, les médias et de nombreux citoyens. Une fracture de confiance 
              tantôt légitime, tantôt exagérée, 
              qui se révèle chaque jour un peu plus problématique pour pouvoir faire 
              face en tant que société aux défis de demain. Si <em>Point de Vue Data</em> est là, c'est parce que 
              <b> je crois que la data, utilisée à des fins informatives, peut contribuer à restaurer cette confiance perdue.</b>
            </p>
            <h4 style={{textAlign: "right"}}>Un engagement et la passion des données</h4>
            <p> 
              Depuis la fin de mes études en 2014, 
              j'ai toujours cherché à mettre le sens au coeur de mon travail. D'une première expérience 
              à l'université à un poste de data scientist dans
              une startup médicale, <a href="https://www.fibricheck.com/" target="_blank" rel="noopener noreferrer">FibriCheck</a>, puis un statut de freelance, j'ai développé mes compétences en IA et en data en ne 
              cessant de me questionner sur la finalité de mes projets.
              <br/> Après avoir suivi le programme entrepreneurial <a href="https://pilote.media/" target="_blank" rel="noopener noreferrer">pilote.media</a>,
              <strong> j'ai lancé <em>Point de Vue Data</em> afin de contribuer à rendre l'information d'où qu'elle vienne plus pertinente,
              plus engageante et plus transparente</strong>. C'est un peu ma pierre dans la lutte contre les fake news et mon engagement 
              pour des lendemains mieux informés et plus confiants.
              <br/> Extraire de nouvelles données, 
              raconter l'histoire de millions de points,
              les traduire en contenus attrayants&hellip; Tout est possible, alors rêvez grand et <em><Link to="/me-contacter/">discutons-en</Link></em>.
            </p>
            </section>
          </article>
      </div>
      </Layout>
    )
  }
}
export default PortfolioPage